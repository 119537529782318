.card .testimonial-left-button {
    position: absolute;
    top: 50%;
    left: 32%;
    height: 8px;
    background-color: #3cbeec;
    z-index: 4;
}
.card-card {
}

.card .testimonial-right-button {
    position: absolute;
    top: 50%;
    right: 32%;
    box-shadow: none;
    background-color: #3cbeec;
    z-index: 4;
}
.card .card-button.right {
    right: 0%;
}
.react-stacked-center-carousel {
    overflow: unset !important;
    align-items: center;
    display: flex;
    @media (max-width: 540px) {
        max-width: 100% !important;
        width: 100%;
        & > div {
            max-width: 100% !important;
            width: 100%;
            left: unset !important;
        }
    }
}

.card-card {
    transition: all 300ms ease;
    width: 100%;
    position: relative;
    background: #1A1919;
    box-shadow: 21px 122px 50px rgba(41, 46, 46, 0.01), 12px 69px 42px rgba(41, 46, 46, 0.05),
        5px 31px 31px rgba(41, 46, 46, 0.09), 1px 8px 17px rgba(41, 46, 46, 0.1), 0px 0px 0px rgba(41, 46, 46, 0.1);
    border-radius: 0;
    overflow: hidden;
    text-align: center;
}

.card-card:hover {
    transform: scale(1.05);
}

.react-stacked-center-carousel-slide-0 .card-card {
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
}

.react-stacked-center-carousel-slide-0 .card-card:hover {
    transform: none;
}

.fill {
    width: 100%;
    height: 100%;
}
.card-carrier {
    background: transparent !important;
    padding-top: 3%;
    border: none;
}

.card-card .card-overlay {
    user-select: none;
    position: absolute;

    cursor: pointer !important;

    transition: all 300ms ease;
    border-radius: 10px;
}

.card-card:hover .card-overlay {
    // background-color: #0000002d;
}

.react-stacked-center-carousel-slide-0 .card-overlay {
    // background-color: #00000099 !important;
}

.card-card .cover {
    position: absolute;
    transition: opacity 300ms ease;
}
.react-stacked-center-carousel-slide-0 .card-card .cover {
    transition: opacity 300ms ease, z-index 0ms 300ms;
}

.card-card .cover.on {
    opacity: 1;
    z-index: 1;
}

.card-card .cover.off {
    opacity: 0;
    z-index: -1;
}

.card-card .detail {
    display: flex;
}
.card-card .video {
    width: 40%;
}
.card-card .discription {
    display: flex;
    flex-direction: column;

    margin: 10px;
}

.card-card .discription > p {
    text-align: center;
    padding-top: 40px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: justified;
}
.card-card > p {
    text-align: center;
    padding-top: 20px;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: justified;
}

@media screen and (max-width: 540px) {
    body {
        overflow-x: hidden;
    }

    .card-card {
        transition: all 300ms ease;
        cursor: pointer;
        width: 100%;
        position: relative;
    }

    .card .testimonial-left-button {
        position: absolute;
        top: 45%;
        left: 0%;
        background-color: #3cbeec;
        z-index: 4;
    }
    .card .testimonial-right-button {
        position: absolute;
        top: 45%;
        left: 90%;
        box-shadow: none;
        background-color: #3cbeec;
        z-index: 4;
    }
}

@media screen and (min-width: 765px) and (max-width: 992px) {
    .card .testimonial-left-button {
        position: fixed;
        top: 25vh;
        left: 17%;
        background-color: red;
        z-index: 4;
    }
    .card .testimonial-right-button {
        position: fixed;
        top: 25%;
        left: 77%;
        box-shadow: none;
        background-color: #3cbeec;
        z-index: 4;
    }
}
