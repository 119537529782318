@import "../../../styles/var";

.card {
}
.cardBody {
}
.cardRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.cardRowLeft {
    flex: 0 1 494px;
    img {
        width: 100%;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        img {
            max-width: 494px;
            margin: 0 auto;
        }
        order: 4;
        text-align: center;
        margin-top: 60px;
    }
}
.cardRowRight {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        order: 3;
    }
}
.cardRowRightText {
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}
