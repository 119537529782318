@import '../../styles/var';

.pageContent {
    position: relative;
    z-index: 10;
    background: #FFF;
}

.blind {
    position: fixed;
    z-index: 3;
    height: 100vh;
    width: 51vw;
    background: #414141;
    @media(max-width: $md3+px) {
        display: none!important;
    }
    &_01 {
        left: 0;
        transform: translateX(-51vw);
    }
    &_02 {
        right: 0;
        transform: translateX(51vw);
    }
}