@import "../../../styles/var";

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 30px);
    }
}
@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -30px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}

.banner {
    padding: 150px 0 0px 0;
    @media (max-width: $md3+px) {
        padding: 80px 0 0px 0;
    }
    @media (max-width: $md6+px) {
        padding: 60px 0 0px 0;
    }
}
.bannerBody {
}
.bannerRow {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        justify-content: center;
    }
}
.bannerImg {
    position: absolute;
    top: -330px;
    left: -360px;
    width: 1208px;
    height: 1193px;
    @media (max-width: $md3+px) {
        left: 0;
        right: 0;
        margin: auto;
        width: 100px;
        top: -280px;
        img {
            position: relative;
            left: -550px;
            width: 1208px !important;
            height: 1193px !important;
        }
    }
    @media (max-width: $md6+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.bannerRowLeft {
}
.bannerRowLeftContent {
    margin: 0 auto;
    @media (max-width: $md4+px) {
    }
    @media (max-width: $md6+px) {
    }
}
.bannerRowLeftContentOverTitle {
    text-align: right;
    position: relative;
    z-index: 10;
    top: 30px;
    overflow: hidden;
    span {
        display: block;
    }
    @media (max-width: $md6+px) {
        text-align: center;
        top: 10px;
        right: 5px;
    }
}
.bannerRowLeftContentTitle,
.bannerRowLeftContentText {
    overflow: hidden;
    span {
        display: block;
    }
}
.bannerRowLeftBtn {
    margin-top: 50px;
    @media (max-width: $md3+px) {
        text-align: center;
        margin-top: 30px;
    }
}
.bannerRowRight {
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerRowRightImgs {
    position: absolute;
    right: 0;
    top: 0;
    width: 617px;
    height: 527px;
}
.bannerRowRightImgsImg1 {
    position: absolute;
    left: -30px;
    top: 250px;
    z-index: 3;
    width: 206px;
    height: 238px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim linear 1.7s alternate infinite");
    }
}
.bannerRowRightImgsImg2 {
    position: absolute;
    right: 120px;
    z-index: 3;
    top: 50px;
    width: 145px;
    height: 159px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim1 linear 1.7s alternate infinite");
    }
}
.bannerRowRightImgsImg3 {
    position: absolute;
    right: 30px;
    top: 180px;
    width: 117px;
    height: 119px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim2 linear 1.7s alternate infinite");
    }
}
.bannerRowRightImgsImg4 {
    position: absolute;
    right: 70px;
    z-index: 3;
    top: 390px;
    width: 171px;
    height: 46px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim1 linear 1.7s alternate infinite");
    }
}
.bannerRowRightImgsImg5 {
    position: absolute;
    right: 210px;
    top: 440px;
    width: 75.519px;
    height: 75.112px;
    z-index: 3;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim linear 1.7s alternate infinite");
    }
}
.bannerRowRightImgsMain1Img {
    position: absolute;
    right: -80px;
    top: 0;
    width: 383px;
    height: 527px;
    position: relative;
    text-align: center;
    overflow: hidden;
    img {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        bottom: 0;
        position: absolute;
        width: 100%;
        height: 470px;
        border-radius: 200px 200px 0px 0px;
        background: linear-gradient(180deg, #34D6EB 0%, #2958E4 100%);
    }
}

.bannerMob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
        text-align: center;
        margin-top: 50px;
        position: relative;
        z-index: 2;
        img {
            max-width: 100%;
        }
    }
}
