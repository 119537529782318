@import '../../../styles/var';

.brokerProgram {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 80px;
    @media(max-width: $md3+px) {
        flex-direction: column;
        justify-content: unset;
        gap: 55px;
    }
    @media(max-width: $md6+px) {
        gap: 50px;
    }
    .block {
        width: 525px;
        @media(max-width: $md3+px) {
            width: 100%;
            &:nth-child(2) {
                width: 100%;
                max-width: 540px;
                margin: 0 auto;
            }
        }
        .title {

        }
        .text {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md4+px) {
                margin-top: 30px;
            }
            b {
                color: #2A59E5;
                font-weight: 700;
            }
        }
        .rangeData {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            .col {
                &:nth-child(2) {
                    text-align: right;
                }
                &Title {
                    color: #2A59E5;
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 150%;
                    @media(max-width: $md4+px) {
                        font-size: 32px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 20px;
                    }
                }
                &Text {
                    color: #131217;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 150%;
                    @media(max-width: $md4+px) {
                        font-size: 16px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
            }
        }
        .rangeWrap {
            margin-top: 60px;
            @media(max-width: $md4+px) {
                margin-top: 50px;
            }
            .inputRange {
                width: 100%;
            }
            &Values {
                display: flex;
                justify-content: space-between;
                color: #2A59E5;
                font-size: 25px;
                font-weight: 400;
                line-height: 150%;
                @media(max-width: $md4+px) {
                    font-size: 20px;
                    margin-top: 5px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
            input[type="range"] {
                -webkit-appearance: none;
                margin-right: 15px;
                width: 100%;
                height: 5px;
                background: #7C7C7C;
                border-radius: 5px;
                background-image: linear-gradient(#2A59E5, #2A59E5);
                background-size: 14% 100%;
                background-repeat: no-repeat;
            }
            input[type="range"]::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #2A59E5;
                cursor: pointer;
                box-shadow: 0 0 2px 0 #555;
                transition: background .3s ease-in-out;
                @media(max-width: $md6+px) {
                    height: 18px;
                    width: 18px;
                }
            }
            input[type="range"]::-moz-range-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #2A59E5;
                cursor: pointer;
                box-shadow: 0 0 2px 0 #555;
                transition: background .3s ease-in-out;
                @media(max-width: $md6+px) {
                    height: 18px;
                    width: 18px;
                }
            }
            input[type="range"]::-ms-thumb {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: #2A59E5;
                cursor: pointer;
                box-shadow: 0 0 2px 0 #555;
                transition: background .3s ease-in-out;
                @media(max-width: $md6+px) {
                    height: 18px;
                    width: 18px;
                }
            }
            input[type="range"]::-webkit-slider-thumb:hover {
                background: #2A59E5;
            }
            input[type="range"]::-moz-range-thumb:hover {
                background: #2A59E5;
            }
            input[type="range"]::-ms-thumb:hover {
                background: #2A59E5;
            }
            input[type=range]::-webkit-slider-runnable-track  {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }
            input[type=range]::-moz-range-track {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }
            input[type="range"]::-ms-track {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }
        }
    }
}