@import '../../../styles/var';

.partnership {
    position: relative;
    display: flex;
    gap: 108px;
    padding-left: 50px;
    @media(max-width: $md3+px) {
        padding-left: 0;
        gap: 0;
        display: block;
    }
    .image {
        position: relative;
        z-index: 10;
        margin-top: 20px;
        width: 231px;
        @media(max-width: $md3+px) {
            position: absolute;
            margin-top: 0;
            width: 141px;
            bottom: 0;
            left: -350px;
            right: 0;
            margin: 0 auto;
        }
        @media(max-width: 600px) {
            left: -260px;
            width: 120px;
        }
        @media(max-width: $md6+px) {
            width: 101px;
            left: 0;
            right: unset;
            margin: 0;
        }
        img {
            width: 100%;
        }
    }
    .content {
        position: relative;
        z-index: 10;
        .titleWrap {
            @media(max-width: $md3+px) {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .title {
                position: relative;
                @media(max-width: $md3+px) {
                    display: flex;
                }
                &Text_01 {
                    @media(max-width: $md5+px) {
                        font-size: 80px;
                    }
                    @media(max-width: 600px) {
                        font-size: 55px;
                    }
                }
                &Text_02 {
                    position: absolute;
                    right: 103px;
                    bottom: -30px;
                    @media(max-width: $md4+px) {
                        right: 90px;
                    }
                    @media(max-width: $md5+px) {
                        font-size: 35px;
                        right: 65px;
                        bottom: -25px;
                    }
                    @media(max-width: 600px) {
                        font-size: 18px;
                        right: 40px;
                        bottom: -12px;
                    }
                }
            }
        }
        .list {
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-top: 70px;
            @media(max-width: $md3+px) {
                margin-left: 50%;
                margin-top: 85px;
            }
            @media(max-width: $md6+px) {
                margin-top: 58px;
                margin-left: 138px;
            }
            .li {
                &Title {
                    color: #131217;
                    font-family: $sans;
                    font-size: 25px;
                    font-weight: 800;
                    line-height: normal;
                    @media(max-width: $md4+px) {
                        font-size: 20px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
                &Text {
                    color: #131217;
                    font-weight: 400;
                    margin-top: 15px;
                }
            }
        }
    }
}