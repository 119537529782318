@import "../../../styles/var";

.our {
    @media (max-width: $md6+px) {
        margin-top: 140px;
    }
}
.ourBody {
}
.ourTitle {
    margin-bottom: 65px;
    text-align: center;
    @media (max-width: $md3+px) {
        margin-bottom: 55px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 30px;
        text-align: left;
    }
}
.ourRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.ourRowLeft {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.ourRowLeftTitle {
    color: #2A59E5 !important;
    margin-bottom: 15px;
    font-weight: 700;
}
h4.ourRowLeftTitle {
    font-weight: 700;
}
.ourRowLeftSubTitle {
    font-weight: 600 !important;
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        margin-bottom: 15px;
    }
}
.ourRowLeftText {
}
.ourRowRight {
    flex: 0 1 525px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.ourBtn {
    margin-top: 50px;
}
