@import '../../../styles/var';

.startEarningContainer {
    padding-top: 60px;
    @media(max-width: $md3+px) {
        padding-top: 0;
    }
}

.startEarning {
    position: relative;
    background: linear-gradient(90deg, #9CEAFF 1.62%, #69BFF9 58.77%, #4D87FF 98.49%);
    padding: 50px 35px;
    @media(max-width: $md4+px) {
        padding: 0;
        background: transparent;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 525px;
        @media(max-width: $md4+px) {
            max-width: 100%;
        }
        .title {
            color: #252525;
            @media(max-width: $md4+px) {
                color: #131217;
            }
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 30px;
            color: #252525;
            @media(max-width: $md4+px) {
                color: #131217;
            }
            b {
                font-weight: 400;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        bottom: 35px;
        right: 70px;
        width: 441px;
        @media(max-width: $md3+px) {
            width: 300px;
            right: 50px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
    }
}