@import '../../../styles/var';

.whatOffer {
    position: relative;
    .title {
        text-align: center;
        @media(max-width: $md4+px) {
            text-align: left;
        }
    }
    .content {
        display: flex;
        justify-content: space-between;
        gap: 80px;
        margin-top: 65px;
        @media(max-width: $md4+px) {
            flex-direction: column;
            gap: 50px;
            margin-top: 50px;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
            gap: 30px;
        }
        .col {
            width: 525px;
            @media(max-width: $md4+px) {
                width: 100%;
            }
            &Title {
                color: #2A59E5;
                font-weight: 700;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 15px;
                margin-top: 30px;
                padding-left: 30px;
                @media(max-width: $md6+px) {
                    padding-left: 25px;
                }
                li {
                    list-style-type: disc;
                    list-style-position: outside;
                }
            }
        }
    }
}