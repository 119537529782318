@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(levitating-1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 30px);
    }
}
@include keyframes(levitating-2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -30px);
    }
}
@include keyframes(levitating-3) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}
@include keyframes(levitating-4) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}