@import '../../../styles/var';

.whyChoose {
    position: relative;
    .title {
        text-align: center;
    }
    .cards {
        margin-top: 65px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        justify-items: center;
        text-align: center;
        @media(max-width: $md4+px) {
            margin-top: 50px;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
        @media(max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
        .card {
            &:hover {
                .cardIcon {
                    transform: scale(0.95);
                }
            }
            &Icon {
                transition: all .3s;
                @media(max-width: $md6+px) {
                    height: 50px;
                }
            }
            &Text {
                margin-top: 30px;
                @media(max-width: $md6+px) {
                    margin-top: 20px;
                }
            }
        }
    }
}