@import "../../../styles/var";

.button {
    padding: 13px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    background: #2A59E5;
    border-radius: 30px;
    font-family: $sans;
    font-weight: 800;
    font-size: 17px;
    line-height: 20px;
    color: #fff;
    transition: all 0.3s;
    border: 1px solid #2A59E5;
    @media (max-width: $md2+px) {
        font-size: 16px;
        line-height: 19px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
        line-height: 16px;
    }
    &:hover {
        color: #FE782C;
        background: transparent;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
