@import "../../../styles/var";

.trading {
}
.tradingBody {
}
.tradingRow {
    display: flex;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.tradingRowLeft {
    border-radius: 230px 0px 0px 230px;
    background: #191919;
    padding: 65px 52px 65px 130px;
    @media (max-width: $md2+px) {
        padding: 65px 52px 65px 90px;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
}
.tradingRowLeftOverTitle {
    color: #D8D8D8 !important;
    text-align: right;
    position: relative;
    top: 60px;
}
.tradingRowLeftTitle {
    padding-top: 30px;
}
.tradingRowLeftText {
    max-width: 281px;
    color: #D8D8D8 !important;
}
.tradingRowRight {
    padding: 65px 0px 65px 49px;
    position: relative;
    @media (max-width: $md3+px) {
        padding: 0;
        margin-top: 140px;
    }
    @media (max-width: $md6+px) {
        margin-top: 90px;
    }
}
.tradingRowRightImg {
    position: absolute;
    right: 0;
    top: 50px;
    @media (max-width: $md3+px) {
        top: 0px;
    }
    @media (max-width: $md6+px) {
        top: -40px;
    }
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.tradingRowRightTitle {
    padding-top: 30px;
}
.tradingRowRightOverTitle {
    position: relative;
    top: 70px;
    right: -80px;
    @media (max-width: $md4+px) {
        top: 60px;
        right: -60px;
    }
    @media (max-width: $md6+px) {
        top: 40px;
        right: -40px;
    }
}
.tradingRowRightSubTitle {
    text-align: right;
    position: relative;
    top: -35px;
    @media (max-width: $md6+px) {
        top: -20px;
    }
}
.tradingRowRightText {
    max-width: 281px;
    position: relative;
    top: -35px;
    @media (max-width: $md6+px) {
        top: -10px;
    }
}
.tradingRowRightBtn {
    position: absolute;
    bottom: 90px;
    right: 0;
    @media (max-width: $md3+px) {
        bottom: 20px;
    }
    @media (max-width: $md6+px) {
        bottom: -60px;
    }
}

.tradingMobBlock {
    display: none;
    border-radius: 200px;
    background: #191919;
    padding: 50px 0;
    max-width: 688px;
    margin: 0 auto;
    text-align: center;
    @media (max-width: $md3+px) {
        display: block;
    }
    @media (max-width: $md6+px) {
        padding: 22px 0;
    }
}
.tradingMobBlockTitle {
    margin-bottom: 30px;
    color: #D8D8D8 !important;
    @media (max-width: $md6+px) {
        margin-bottom: 10px;
    }
}
.tradingMobBlockText {
    color: #D8D8D8 !important;
    @media (max-width: $md6+px) {
        max-width: 241px;
        margin: 0 auto;
    }
}
