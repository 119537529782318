@import '../../../styles/var';

.whyBecomeWrap {
    position: relative;
    padding-bottom: 80px;
    @media(max-width: $md3+px) {
        padding-bottom: 0;
    }
}

.whyBecome {
    position: relative;
    .title {
        text-align: center;
    }
    .cardsWrap {
        position: relative;
        .cards {
            position: relative;
            margin-top: 110px;
            display: grid;
            grid-template-columns: repeat(4, 362px);
            grid-template-rows: repeat(1, 1fr);
            gap: 0;
            @media(max-width: $md3+px) {
                margin-top: 55px;
                display: flex;
                flex-direction: column;
                gap: 30px;
            }
            @media(max-width: $md6+px) {
                margin-top: 30px;
            }
            .card {
                position: absolute;
                cursor: pointer;
                border-radius: 20px;
                padding: 25px;
                width: 362px;
                height: 100%;
                top: 0;
                @media(max-width: $md3+px) {
                    position: relative!important;
                    left: unset!important;
                    right: unset!important;
                    transform: unset!important;
                    background: transparent!important;
                    width: 100%;
                    padding: 0;
                }
                &:nth-child(1) {
                    position: relative;
                    background: #414141;
                    transform: translateX(100vw);
                    .cardTitle, .cardText {
                        color: #FFF;
                    }
                }
                &:nth-child(2) {
                    background: #1A1919;
                    left: 298px;
                    transform: translateX(100vw);
                    .cardTitle, .cardText {
                        color: #FFF;
                    }
                }
                &:nth-child(3) {
                    background: #2D6EE8;
                    transform: translateX(100vw);
                    right: 319px;
                    .cardTitle, .cardText {
                        color: #FFF;
                    }
                }
                &:nth-child(4) {
                    background: #646464;
                    right: 0;
                    transform: translateX(100vw);
                    .cardTitle, .cardText {
                        color: #FFF;
                    }
                }
                &Title {
                    font-weight: 800;
                    @media(max-width: $md3+px) {
                        font-weight: 600;
                        color: #000!important;
                    }
                }
                &Text {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 150%;
                    margin-top: 24px;
                    @media(max-width: $md3+px) {
                        color: #000!important;
                        margin-top: 15px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}