@import "../../../styles/var";

.banner {
    position: relative;
    padding-top: 270px;
    padding-bottom: 20px;
    @media (max-width: $md2+px) {
        padding-top: 220px;
    }
    @media (max-width: $md3+px) {
        padding-top: 180px;
        padding-bottom: 0;
    }
    @media (max-width: $md6+px) {
        padding-top: 150px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 600px;
        margin-left: auto;
        @media (max-width: $md3+px) {
            margin-left: 0;
        }
        .title {
            span {
                display: block;
            }
        }
        .subtitle {
            overflow: hidden;
            margin: 20px 0 30px 0;
            @media (max-width: $md6+px) {
                margin-top: -10px;
            }
            span {
                line-height: 120%;
                display: block;
                &:nth-child(2) {
                    margin-top: 10px;
                }
            }
        }
        .text {
            max-width: 525px;
            display: block;
            overflow: hidden;
            span {
                display: block;
            }
        }
    }
    .image {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 381px;
        @media (max-width: $md2+px) {
            width: 100%;
            max-width: 380px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 65px auto 0 auto;
        }
        @media (max-width: $md6+px) {
            margin-top: 50px;
            max-width: 220px;
        }
    }
}
