@import "../../../styles/var";

.make {
}
.makeBody {
}
.makeTitle {
    text-align: center;
    margin-bottom: 65px;
    @media (max-width: $md3+px) {
        margin-bottom: 55px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 30px;
        text-align: left;
    }
}
.makeRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1150px) {
        flex-wrap: wrap;
    }
}
.makeRowColumnItem {
}
.makeRowColumn {
    flex: 0 1 370px;
    padding: 40px 40px 25px 40px;
    @media (max-width: $md3+px) {
        flex: 0 1 350px;
        padding: 40px 20px 25px 20px;
    }
    @media (max-width: 1150px) {
        padding: 0px;
        background: transparent !important;
        flex: 0 1 100%;
        .makeRowColumnTitle,
        .makeRowColumnText {
            color: #131217 !important;
        }
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &:nth-child(1) {
        background-color: #414141;
        .makeRowColumnTitle,
        .makeRowColumnText {
            color: #D8D8D8;
        }
    }
    &:nth-child(2) {
        background-color: #1A1919;
        .makeRowColumnTitle,
        .makeRowColumnText {
            color: #D8D8D8;
        }
    }
    &:nth-child(3) {
        background-color: #2A59E5;
        .makeRowColumnTitle,
        .makeRowColumnText {
            color: #D8D8D8;
        }
        .makeRowColumnBtn {
            text-align: right;
        }
    }
}
.makeRowColumnTitle {
    margin-bottom: 30px;
    @media (max-width: 1150px) {
        margin-bottom: 15px;
    }
}
.makeRowColumnText {
}
.makeRowColumnBtn {
    margin-top: 50px;
    @media (max-width: 1150px) {
        display: none;
    }
}
