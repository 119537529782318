@import "../../../styles/var";

.will {
}
.willBody {
}
.willTitle {
    text-align: center;
    margin-bottom: 65px;
    @media (max-width: $md5+px) {
        margin-bottom: 50px;
    }
}
.willRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        max-width: 700px;
        justify-content: center;
        gap: 10px;
        margin: 0 auto;
    }
    @media (max-width: $md5+px) {
        max-width: 600px;
    }
    @media (max-width: $md6+px) {
        max-width: 400px;
    }
}
.willRowColumn {
    text-align: center;
    @media (max-width: $md3+px) {
        flex: 0 1 210px;
    }
    span {
        display: block;
    }
    &:first-child {
        margin-bottom: 80px;
        @media (max-width: $md5+px) {
            margin-bottom: 50px;
        }
    }
    &:nth-child(3) {
        @media (max-width: $md5+px) {
            margin-bottom: 50px;
        }
    }
    @media (max-width: $md6+px) {
        margin-bottom: 50px !important;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
.willRowColumnItem {
}
.willRowColumnItemImg {
    margin-bottom: 30px;
    @media (max-width: $md5+px) {
        margin-bottom: 15px;
    }
}
.willRowColumnItemText {
}
