@import '../../../styles/var';

.tradingHoursWrap {
    position: relative;
    background: #EAF7FA;

    margin-top: 100vh;
    @media(max-width: $md3+px) {
        margin-top: 0;
    }
}
.tradingmain{
    background: linear-gradient(93deg, #34D6EB 16.39%, #2958E4 92.38%)!important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
    background-clip: text!important;
}
.tradingHours {
    position: relative;
    padding: 130px 0 100px 0;
    @media(max-width: $md3+px) {
        padding: 80px 0 65px 0;
    }
    @media(max-width: $md6+px) {
        padding: 60px 0 50px 0;
    }
    .titleWrap {
        display: flex;
        justify-content: center;
    }
    .title {
        display: inline-flex;
        flex-direction: column;
        color: #131217;
        &Text_01 {
            font-family: $sans;
            font-size: 160px;
            font-weight: 800;
            line-height: 1;
            @media(max-width: $md4+px) {
                font-size: 105px;
            }
            @media(max-width: $md6+px) {
                font-size: 70px;
            }
        }
        &Text_02 {
            font-family: $sans;
            font-size: 65px;
            font-weight: 800;
            line-height: normal;
            margin-top: -30px;
            padding-left: 27px;
            @media(max-width: $md4+px) {
                font-size: 45px;
                margin-top: -15px;
                padding-left: 17px;
            }
            @media(max-width: $md6+px) {
                font-size: 30px;
                margin-top: -10px;
                padding-left: 11px;
            }
        }
    }
    .tableWrap {
        position: relative;
        margin: 42px 0 170px 0;
        @media(max-width: $md3+px) {
            margin: 65px 0 140px 0;
        }
        @media(max-width: 600px) {
            width: calc(100% + 36px);
            transform: translateX(-18px);
            overflow: scroll;
            overflow-y: hidden;
            padding-bottom: 10px;
        }
        @media(max-width: $md6+px) {
            margin: 50px 0 90px 0;
        }
        table {
            width: 100%;
            border-spacing: 0;
            max-width: 714px;
            margin: 0 auto 0 auto;
            color: #131217;
            font-family: $sans;
            font-size: 14px;
            font-weight: 300;
            line-height: normal;
            @media(max-width: 600px) {
                padding: 0 18px;
                width: 630px;
            }
            tr {
                &:nth-child(1) {
                    font-weight: 400;
                }
                td {
                    border-bottom: 1px solid #131217;
                    height: 50px;
                    padding: 0 10px;
                }
            }
        }
    }
    .cardsWrap {
        .cardsContainer {

            .cards {
                position: relative;
                display: grid;
                grid-template-columns: repeat(6, 357px);
                grid-template-rows: repeat(1, 1fr);
                gap: 60px;
                @media(max-width: $md3+px) {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                }
                .card {
                    background-color: #2D6AE8; 
                    position: relative;
                    border: 1px solid #FFF;
                    padding: 25px 25px 25px 20px;
                    @media(max-width: $md3+px) {
                        padding: 0;
                        border: none;
                    }
                    &Title {
                        color:#FFF;
                    }
                    &Text {
                        color: #FFF;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
