@import '../../../styles/var';

.tabsWrap {
    position: relative;
    width: 950px;
    margin: 0 auto;
    @media(max-width: $md3+px) {
        width: 100%;
    }
    .buttons {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 40px;
        gap: 35px;
        @media(max-width: $md3+px) {
            gap: 20px;
        }
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 40px);
        }
        @media(max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 40px);
        }
        .button {
            position: relative;
            border-radius: 30px;
            border: 1px solid #131217;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            transition: all .1s, color 0s;
            color: #131217;
            font-family: $sans;
            font-size: 15px;
            font-weight: 400;
            line-height: normal;
            @media(max-width: $md6+px) {
                font-size: 14px;
            }
            &Active {
                color: #FFF;
                background: #2A59E5;
                border-color: #2A59E5;
            }
        }
    }
    .tableWrap {
        position: relative;
        width: 100%;
        margin-top: 65px;
        @media(max-width: $md4+px) {
            overflow: scroll;
            overflow-y: hidden;
            padding-bottom: 15px;
            width: calc(100% + 80px);
            transform: translateX(-40px);
        }
        @media(max-width: 600px) {
            width: calc(100% + 40px);
            transform: translateX(-20px);
        }
        @media(max-width: $md6+px) {
            margin-top: 50px;
        }
        table {
            width: 100%;
            border-spacing: 0;
            color: #131217;
            font-family: $sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            @media(max-width: $md4+px) {
                width: 1100px;
                padding: 0 40px;
            }
            @media(max-width: 600px) {
                padding: 0 20px;
                width: 900px;
            }
            span {
                font-weight: 400;
            }
            tr {
                td {
                    height: 50px;
                    border-bottom: 1px solid #AAA;
                }
            }   
        }
    }
}