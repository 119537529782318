@import '../../../styles/var';

.ourRewards {
    position: relative;
    margin-top: 160px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        text-align: center;
    }
    .awards {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 75px;
        @media(max-width: $md3+px) {
            justify-content: space-between;
        }
        @media(max-width: $md4+px) {
            margin-top: 65px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 65px 0;
            justify-items: center;
        }
        @media(max-width: 600px) {
            display: none;
        }
    }
    .award {
        height: 105px;
        opacity: 0.6;
        transition: all .3s;
        &:hover {
            opacity: 1;
        }
        @media(max-width: $md4+px) {
            height: 90px;
            &:nth-child(4), &:nth-child(5) {
                transform: translateX(calc(100% + 10px));
            }
        }
        @media(max-width: 800px) {
            &:nth-child(4), &:nth-child(5) {
                transform: translateX(0);
            }
        }
        @media(max-width: 600px) {
            margin: 0 20px;
        }
        @media(max-width: $md6+px) {
            height: 55px;
        }
    }
    .marqueWrap {
        display: none;
        @media(max-width: 600px) {
            display: block;
            width: calc(100% + 36px);
            transform: translateX(-18px);
            margin-top: 50px;
        }
    }
}