@import "../../../styles/var";

.text {
    font-weight: 800;
    font-size: 28px;
    margin-top: 40px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #2f6ade;
}

//.wrap {
//    display: flex;
//}

.ourPatnersBodyRow1Column {
    display: inline-block;
    margin-right: 30px;
    &:last-child {
        margin-right: 0px;
    }
}
.imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 60px;
    border-radius: 20px;
    background-color: #EAF7FA;
}
.ourPatnersBodyRow1 {
    margin-bottom: 30px;
}
.ourPatnersBodyRow2 {
    transform: rotate(180deg);
}
.ourPatnersBodyRow2Wrap {
    .ourPatnersBodyRow1Column {
        transform: rotate(-60deg);
    }
}

.ourPatnersBodyRow2Column {
    display: inline-block;
    transform: rotate(180deg);
    margin-right: 30px;
    &:last-child {
        margin-right: 0;
    }
}

.ourPatnersBodyRow2Column,
.ourPatnersBodyRow1Column {
    @media (max-width: $md6+px) {
        img {
            height: 45px;
        }
    }
}

//h3.ourPatnersTitle {
//    color: #D8D8D8;
//}

.ourPatnersTitle {
    margin-bottom: 75px;
    text-align: center;
    @media (max-width: $md6+px) {
        margin-bottom: 50px;
    }
}
