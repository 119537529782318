@import "../../../styles/var";

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 30px);
    }
}
@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -30px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}

.banner {
    position: relative;
    padding: 183px 0 0px 0;
    overflow: hidden;
    background: #FFF;
    @media (max-width: $md3+px) {
        background: transparent;
        padding: 123px 0 0px 0;
    }
    @media (max-width: $md6+px) {
        padding: 100px 0 0px 0;
    }
}

.bannerBody {
    position: relative;
    z-index: 3;
}
.bannerRow {
    display: flex;
    justify-content: space-between;
}
.bannerRowLeft {
    padding-bottom: 60px;
    @media (max-width: $md3+px) {
        padding-bottom: 50px;
    }
}
.bannerRowLeftTitle {
    margin-bottom: 23px;
    color: #131217 !important;
    @media (max-width: $md3+px) {
        color: #131217 !important;
    }
    overflow: hidden;
    span {
        display: block;
    }
}
.bannerRowLeftText {
    max-width: 505px;
    color: #131217 !important;
    overflow: hidden;
    span {
        display: block;
    }
    margin-bottom: 15px;
    @media (max-width: $md3+px) {
        color: #131217 !important;
    }
    &:last-child {
        margin-bottom: 15px;
    }
}
.bannerRowRight {
    position: relative;
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerRowRightImgs {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.bannerRowRightImgsImg1 {
    position: absolute;
    right: 450px;
    bottom: 140px;
    width: 153.695px;
    height: 152.251px;
    display: block;
    z-index: 2;

    img {
        @include animation("top-anim1 linear 2.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowRightImgsImg2 {
    position: absolute;
    right: 350px;
    bottom: -10px;
    width: 205px;
    height: 202px;
    z-index: 2;
    img {
        @include animation("top-anim linear 2.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowRightImgsImg3 {
    position: absolute;
    right: 100px;
    bottom: 80px;
    width: 187.466px;
    height: 185.228px;
    z-index: 2;
    img {
        @include animation("top-anim2 linear 1.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowRightImgsImg4 {
    // display: none;
    width: 515px;
    height: 466px;
    position: absolute;
    z-index: 1;
    right: 100px;
    bottom: 0;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.mob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
        text-align: center;
        img {
            max-width: 100%;
        }
    }
}
