@import "../../../styles/var";

.numbers {
    padding: 280px 0 150px 0;
    @media (max-width: $md3+px) {
        padding: 140px 0 140px 0;
    }
    @media (max-width: $md4+px) {
        padding: 140px 0 0px 0;
    }
    @media (max-width: $md6+px) {
        padding: 90px 0 0px 0;
    }
}
.numbersBody {
}
.numbersRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
        justify-content: center;
    }
}
.numbersRowColumn {
    text-align: center;
    position: relative;
    span {
        display: block;
        @media (max-width: 700px) {
            display: inline-block;
        }
    }
    &:nth-child(2),
    &:nth-child(4) {
        top: 150px;
        @media (max-width: $md4+px) {
            top: 0;
        }
    }
    @media (max-width: $md4+px) {
        flex: 0 1 33%;
        &:nth-child(2) {
            margin-bottom: 75px;
        }
    }
    @media (max-width: 700px) {
        flex: 0 1 100%;
        text-align: left;
        margin-bottom: 30px !important;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
div.numbersRowColumnNumber {
    color: #fff;
}
.numbersRowColumnNumber {
    width: 60px;
    height: 60px;
    display: flex;
    margin: 0 auto 20px auto;
    align-items: center;
    justify-content: center;
    background-color: #2A59E5;
    border-radius: 50%;
    border: 3px solid #2A59E5;
    @media (max-width: 700px) {
        width: 50px;
        height: 50px;
        flex: 0 0 50px;
    }
}
.numbersRowColumnTitle {
    margin-bottom: 15px;
    @media (max-width: 700px) {
        margin-bottom: 5px;
    }
}
.numbersRowColumnText {
}

.numbersRowColumnMobWrap {
    @media (max-width: 700px) {
        display: flex;
        .numbersRowColumnNumber {
            margin: 0 16px 0 0;
        }
    }
}
