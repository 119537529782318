@import '../../../styles/var';

.bannerWrap {
    position: relative;
    z-index: 1;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    @media(max-width: $md3+px) {
        position: relative;
    }
}

.banner {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100vh;
    padding-bottom: 150px;
    @media(max-width: $md1+px) {
        padding-bottom: 80px;
    }
    @media(max-width: $md2+px) {
        padding-bottom: 50px;
    }
    @media(max-width: $md3+px) {
        padding-bottom: 0;
        height: auto;
        display: block;
        padding-top: 200px;
    }
    @media(max-width: $md6+px) {
        padding-top: 150px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 820px;
        margin-left: auto;
        @media(max-width: $md3+px) {
            max-width: 100%;
            margin-left: 0;
        }
        .title {
            display: flex;
            flex-direction: column;
            @media(max-width: $md3+px) {
                padding-left: 50px;
            }
            @media(max-width: $md5+px) {
                padding-left: 0;
            }
            &Text_01 {
                position: relative;
                overflow: hidden;
                padding-left: 75px;
                line-height: 1.3;
                @media(max-width: $md4+px) {
                    padding-left: 60px;
                }
                @media(max-width: $md6+px) {
                    padding-left: 40px;
                    font-size: 25px;
                }
                .textAnim_01 {
                    position: relative;
                    transform: translateX(-100%);
                    @media(max-width: $md3+px) {
                        transform: none;
                        line-height: normal;
                    }
                }
            }
            &Text_02 {
                position: relative;
                line-height: 1;
                margin: -10px 0 10px 0;
                @media(max-width: $md5+px) {
                    font-size: 80px;
                }
                @media(max-width: $md6+px) {
                    font-size: 60px;
                    margin: -10px 0 0px 0;
                }
                .textAnim_02 {
                    position: relative;
                    transform: translateY(100%);
                    @media(max-width: $md3+px) {
                        transform: none;
                        line-height: normal;
                    }
                }
            }
            &Text_03 {
                position: relative;
                overflow: hidden;
                line-height: 1.4;
                .textAnim_03 {
                    position: relative;
                    transform: translateY(100%);
                    @media(max-width: $md3+px) {
                        transform: none;
                        line-height: normal;
                    }
                }
            }
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 50px 0;
            max-width: 525px;
            @media(max-width: $md3+px) {
                max-width: 100%;
            }
        }
    }
    .woman {
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 70px;
        width: 436px;
        transform: translateY(50px) scale(0.9);
        @media(max-width: $md1+px) {
            width: 360px;
        }
        @media(max-width: $md2+px) {
            width: 320px;
        }
        @media(max-width: $md3+px) {
            transform: none;
            position: relative;
            left: unset;
            bottom: unset;
            display: flex;
            margin: 60px auto 120px auto;
            width: 424px;
        }
        @media(max-width: $md6+px) {
            margin-top: 50px;
            margin-bottom: 80px;
            width: 280px;
        }
    }
}