@import "../../../styles/var";

.footer {
    position: relative;
    z-index: 10;
    color: #131217;
    display: grid;
    grid-template-columns: 130px auto 1fr;
    gap: 70px;
    padding-bottom: 30px;
    @media (max-width: $md1+px) {
        grid-template-columns: 130px auto 1fr;
    }
    @media (max-width: $md2+px) {
        grid-template-columns: 130px auto 1fr;
    }
    @media (max-width: $md3+px) {
        grid-template-columns: 130px 1fr;
        gap: 30px 100px;
        .logo {
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .info {
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .disclaimer {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    @media (max-width: $md5+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    a {
        color: #131217;
        text-decoration: none;
    }
    p {
        color: #131217;
        text-decoration: none;
    }
    .logo {
        width: 100%;
        img {
            width: 130px;
            @media(max-width: $md6+px) {
                width: 100px;
            }
        }
    }
    .info {
        font-family: $sans;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(216, 216, 216, 0.70);
        display: flex;
        gap: 50px;
        justify-content: space-between;
        @media (max-width: $md2+px) {
            flex-direction: column;
            justify-content: unset;
            gap: 15px;
        }
        @media (max-width: $md3+px) {
            justify-content: unset;
            flex-direction: unset;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }
        .contacts,
        .docs {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
    .disclaimer {
        font-family: $sans;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        &Title {
            font-weight: 400;
            margin-bottom: 6px;
        }
        .rights {
            text-align: right;
            font-family: $sans;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-top: 10px;
            @media (max-width: $md1+px) {
                margin-top: 30px;
            }
            @media (max-width: $md4+px) {
                margin-top: 20px;
                text-align: left;
            }
        }
    }
}
