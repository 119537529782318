@import '../../../styles/var';

.accountTypes {
    position: relative;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    .buttons {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(1, 45px);
        gap: 15px;
        @media(max-width: $md3+px) {
            gap: 20px;
        }
        @media(max-width: $md4+px) {
            grid-template-rows: repeat(2, 40px);
        }
        @media(max-width: $md5+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        .button {
            position: relative;
            border-radius: 20px;
            border: 1px solid #131217;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: 600;
            color: #131217;
            background: transparent;
            cursor: pointer;
            transition: all 0.1s, color 0s;
            &Active {
                color: #FFF;
                background: #2A59E5;
                border-color: #2A59E5;
            }
        }
    }
    .card {
        border-radius: 20px;
        background: #414141;
        box-shadow: 0px 333px 93px 0px rgba(83, 72, 10, 0.00), 0px 213px 85px 0px rgba(83, 72, 10, 0.01), 0px 120px 72px 0px rgba(83, 72, 10, 0.05), 0px 53px 53px 0px rgba(83, 72, 10, 0.09), 0px 13px 29px 0px rgba(83, 72, 10, 0.10), 0px 0px 0px 0px rgba(83, 72, 10, 0.10);
        padding: 45px 50px;
        margin-top: 50px;
        @media(max-width: $md5+px) {
            padding: 35px;
        }
        @media(max-width: $md6+px) {
            padding: 15px;
        }
        .title {
            color: #D8D8D8;
            text-align: center;
            font-family: $sans;
            font-size: 30px;
            font-weight: 800;
            line-height: normal;
            @media(max-width: $md4+px) {
                font-size: 25px;
            }
            @media(max-width: $md6+px) {
                font-size: 16px;
            }
        }
        .rows {
            margin: 35px 0 24px 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: #D8D8D8;
            font-family: $sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            @media(max-width: $md6+px) {
                margin-top: 30px;
                font-size: 16px;
            }
            .row {
                b {
                    font-weight: 400;
                }
            }
        }
        .btn {
            text-align: center;
        }
    }
}
