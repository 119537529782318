@import "./_var.scss";

body,
html {
    background-color: #FFF;
    color: #131217;
    &.lock {
        overflow: hidden;
    }
}

.font-120 {
    background: linear-gradient(93deg, #34D6EB 16.39%, #2958E4 92.38%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: $sans;
    font-size: 120px;
    font-weight: 800;
    line-height: normal;
    @media (max-width: $md4+px) {
        font-size: 80px;
    }
    @media (max-width: $md6+px) {
        font-size: 50px;
    }
}

.font-100 {
    background: linear-gradient(93deg, #34D6EB 16.39%, #2958E4 92.38%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: $sans;
    font-size: 100px;
    font-weight: 800;
    line-height: 100%;
    @media (max-width: $md4+px) {
        font-size: 80px;
    }
    @media (max-width: $md6+px) {
        font-size: 50px;
    }
}

.font-50 {
    color: #131217;
    font-family: $sans;
    font-size: 50px;
    font-weight: 800;
    line-height: normal;
    @media (max-width: $md4+px) {
        font-size: 45px;
    }
    @media (max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-40 {
    color: #131217;
    font-family: $sans;
    font-size: 40px;
    font-weight: 800;
    line-height: normal;
    @media (max-width: $md4+px) {
        font-size: 35px;
    }
    @media (max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-35 {
    color: #131217;
    font-family: $sans;
    font-size: 35px;
    font-weight: 800;
    line-height: normal;
    @media (max-width: $md4+px) {
        font-size: 32px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-30 {
    color: #131217;
    font-family: $sans;
    font-size: 30px;
    font-weight: 800;
    line-height: normal;
    @media (max-width: $md4+px) {
        font-size: 27px;
    }
    @media (max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-25-cards {
    color: #131217;
    font-family: $sans;
    font-size: 25px;
    font-weight: 800;
    line-height: normal;
    @media(max-width: $md3+px) {
        font-size: 20px;
        font-weight: 600;
    }
    @media(max-width: $md4+px) {
        font-size: 16px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-20 {
    font-family: $sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media (max-width: $md4+px) {
        font-size: 16px;
        font-weight: 600;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-18 {
    color: #131217;
    font-family: $sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-17 {
    color: #131217;
    font-family: $sans;
    font-size: 17px;
    font-weight: 300;
    line-height: 150%;
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-16 {
    font-family: $sans;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.mt {
    margin-top: 170px;
    @media (max-width: $md1+px) {
        margin-top: 140px;
    }
    @media (max-width: $md6+px) {
        margin-top: 90px;
    }
}

.pt {
    padding-top: 170px;
    @media (max-width: $md1+px) {
        padding-top: 140px;
    }
    @media (max-width: $md6+px) {
        padding-top: 90px;
    }
}

.pb {
    padding-bottom: 170px;
    @media (max-width: $md1+px) {
        padding-bottom: 140px;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 90px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Dropdown-control {
    font-family: $sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: #131217;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $sans;
    font-style: normal;
    font-size: 14px;
}
.Dropdown-arrow {
    border-color: #000 transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #000;
}
