@import "../../../styles/var";

.banner {
    position: relative;
    padding-top: 270px;
    @media (max-width: $md1+px) {
        padding-top: 220px;
    }
    @media (max-width: $md4+px) {
        padding-top: 180px;
    }
    @media (max-width: $md6+px) {
        padding-top: 150px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .orange {
                color: #2A59E5;
            }
            overflow: hidden;
            span {
                display: block;
            }
        }
        .text {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            max-width: 525px;
            overflow: hidden;
            span {
                display: block;
            }
            @media (max-width: $md4+px) {
                max-width: 100%;
            }
            @media (max-width: $md6+px) {
                margin-top: 30px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        width: 599px;
        @media (max-width: $md2+px) {
            width: 520px;
        }
        @media (max-width: $md3+px) {
            width: 100%;
            max-width: 480px;
        }
        @media (max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 60px auto 0 auto;
            right: unset;
            bottom: unset;
        }
        @media (max-width: $md6+px) {
            margin-top: 50px;
        }
    }
}
