@import "../../../styles/var";

.container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 630px;
    gap: 70px;
    @media (max-width: $md2+px) {
        display: flex;
    }

    @media (max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 65px;
    }
    @media (max-width: $md6+px) {
        gap: 50px;
    }
    &Right {
        grid-template-columns: 630px 1fr;
        @media (max-width: $md3+px) {
            flex-direction: column-reverse;
        }
        .header {
            img {
                width: 100%;
                max-width: 100%;
            }
            .title {
                max-width: 525px !important;
                text-align: right;
                margin-left: auto;
                @media (max-width: $md3+px) {
                    text-align: left;
                    margin-left: unset;
                }
            }
        }
    }
    .header {
        .title {
            max-width: 435px;
            font-family: $sans;
            font-weight: 800;
            font-size: 70px;
            line-height: 140%;

            @media (max-width: $md3+px) {
                font-size: 35px;
                max-width: 100%;
            }
            @media (max-width: $md4+px) {
                font-size: 30px;
            }
            @media (max-width: $md6+px) {
                font-size: 25px;
            }
        }
        .text {
            font-weight: 600;
            margin-top: 30px;
            max-width: 418px;
            @media (max-width: $md4+px) {
                max-width: 350px;
            }
        }
        .bg_01 {
            position: absolute;
            z-index: 1;
            width: 378px;
            right: 0;
            top: 300px;
            @media (max-width: $md3+px) {
                display: none;
            }
        }
        .bg_02 {
            position: absolute;
            z-index: 1;
            width: 501px;
            right: -120px;
            top: 260px;
            @media (max-width: $md3+px) {
                display: none;
            }
        }
    }
    .tabs {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .tab {
            position: relative;
            display: grid;
            grid-template-columns: 9px 1fr;
            gap: 0 17px;
            align-items: center;
            padding: 15px 30px;
            background: rgba(11, 153, 111, 0);
            transition: background 0.1s;
            cursor: pointer;
            @media (max-width: $md4+px) {
                padding: 15px 25px;
            }
            @media (max-width: $md6+px) {
                padding: 15px;
            }
            &:hover {
                background: #414141;
            }
            .status {
                width: 100%;
                transition: all 0.1s;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            &Title {
                color: #131217;
            }
            &Text {
                font-family: $sans;
                font-weight: 300;
                font-size: 14px;
                line-height: 140%;
                color: #D8D8D8;
                display: none;
                padding-top: 15px;
            }
            &Active {
                background: #414141 !important;
                .tabText {
                    display: block;
                }
                .status {
                    transform: rotate(-90deg);
                }
                .tabTitle {
                    color: #D8D8D8!important;
                }
            }
        }
    }
}

.header {
    @media (max-width: $md3+px) {
        text-align: center;

        img {
            max-width: 100%;
        }
    }
}
