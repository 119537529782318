@import '../../../styles/var';

.affiliateProgram {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        .title {

        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 50px 0;
            max-width: 525px;
            @media(max-width: $md4+px) {
                max-width: 100%;
            }
            @media(max-width: $md6+px) {
                margin-top: 30px;
            }
            b {
                font-weight: 700;
                color: #2A59E5;
            }
        }
        .btn {

        }
    }
    .image {
        position: absolute;
        z-index: 1;
        width: 520px;
        right: 70px;
        top: 0;
        @media(max-width: $md3+px) {
            width: 350px;
            top: 50px;
            right: 0;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0 auto;
            top: unset;
            right: unset;
            width: 100%;
            max-width: 415px;
        }
    }
}