@import "../../../styles/var";

.trade {
}
.tradeTop {
    position: relative;
    z-index: 3;
}
.tradeTopTitle {
    text-align: center;

    margin: 0 auto 75px auto;
    @media (max-width: 630px) {
        text-align: left;
    }
    @media (max-width: $md6+px) {
        margin: 0 auto 50px auto;
    }
}
.tradeTopSubtitle {
    max-width: 525px;
    text-align: center;
    margin: 0 auto 75px auto;
    span {
        display: block;
    }
    @media (max-width: 630px) {
        text-align: left;
        margin: 0 0 50px 0;
        span {
            display: inline;
        }
    }
}
.tradeRow {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    position: relative;
    z-index: 3 !important;
    margin: 0 auto;
    @media (max-width: 630px) {
        display: none;
    }
}
.tradeRowLeft {
    flex: 0 1 777px;
    @media (max-width: $md3+px) {
        display: flex;
        flex-wrap: wrap;
        flex: 0 1 100%;
        justify-content: space-between;
    }
}
.tradeRowLeftTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        max-width: 49%;
        flex: 0 1 49%;
        margin-bottom: 0;
    }
}
.tradeRowLeftTopLeft {
    flex: 0 1 370px;
    background-color: #33C9EB;
    color: #000;
    padding: 41px 25px 40px 25px;
    @media (max-width: 670px) {
        padding: 31px 15px 30px 15px;
    }
    position: relative;
    @media (max-width: $md3+px) {
        margin-bottom: 20px;
        flex: 0 1 100%;
    }
    // &:after {
    //     content: "";
    //     display: inline-block;
    //     left: 0;
    //     top: 0;
    //     position: absolute;
    //     width: 100%;
    //     pointer-events: none;
    //     height: 100%;
    //     background: url("../../../assets/img/products/2/1.png") 0 0 / cover no-repeat;
    // }
}
.tradeRowLeftTopLeftTitle {
    margin-bottom: 30px;
}
.tradeRowLeftTopLeftSubtitle {
    margin-bottom: 17px;
}
.tradeRowLeftTopLeftBtn {
    display: flex;
    justify-content: space-between;
    img {
        // transition: all 0.3s linear;
    }
    a {
        &:hover {
            img {
                filter: invert(86%) sepia(41%) saturate(1645%) hue-rotate(327deg) brightness(103%) contrast(94%);
            }
        }
    }
}

.tradeRowLeftTopRight {
    flex: 0 1 370px;
    color: #fff;
    background-color: #191919;
    padding: 41px 20px 40px 20px;
    @media (max-width: 670px) {
        padding: 31px 15px 30px 15px;
    }
    position: relative;
    @media (max-width: $md3+px) {
        height: 374px;
        flex: 0 1 100%;
    }
    @media (max-width: 670px) {
        height: 380px;
    }

    &:after {
        content: "";
        display: inline-block;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        height: 100%;
        width: 238px;
        height: 182px;
        // background: url("../../../assets/img/accounts/3/1.png") center 0 / contain no-repeat;
    }
}
.tradeRowLeftTopRightTitle {
    margin-bottom: 30px;
    max-width: 330px;
}
.tradeRowLeftTopRightText {
}
.tradeRowLeftBottom {
    background-color: #414141;
    padding: 41px 25px 40px 25px;
    @media (max-width: 670px) {
        padding: 31px 15px 30px 15px;
    }
    position: relative;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-top: 20px;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        height: 100%;
         background: url("../../../assets/img/main/4/bg-why.png") right 0 / contain no-repeat;
    }
}
.tradeRowLeftBottomTitle {
    margin-bottom: 30px;
    color: #FFF !important;
}
.tradeRowLeftBottomText {
    max-width: 321px;
    color: #FFF !important;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}
.tradeRowRight {
    &.mob {
        display: none;
    }
    @media (max-width: $md3+px) {
        display: none;
        &.mob {
            display: block;
            max-width: 49%;
            flex: 0 1 49%;
        }
    }
    flex: 0 1 370px;
    padding: 41px 25px 40px 25px;
    @media (max-width: 670px) {
        padding: 31px 15px 30px 15px;
    }

    color: #FFF;
    background-color: #191919;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        height: 100%;
        // background: url("../../../assets/img/products/2/3.png") right bottom / 196px 449px no-repeat;
    }
    &:before {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        z-index: 1;
        height: 100%;
        // background: url("../../../assets/img/accounts/3/4.png") right bottom / 370px 323px no-repeat;
        @media (max-width: $md4+px) {
            // background: url("../../../assets/img/accounts/3/4.png") right bottom / 300px 253px no-repeat;
        }
        @media (max-width: 700px) {
            // background: url("../../../assets/img/accounts/3/4.png") right bottom / 250px 203px no-repeat;
        }
    }
}
.tradeRowRightTitle {
    margin-bottom: 30px;
    max-width: 330px;
}
.tradeRowRightText {
    margin-bottom: 30px;
}
.tradeRowRightBtn {
    position: absolute;
    right: 40px;
    bottom: 55px;
    z-index: 2;
}

.tradeMob {
    display: none;
    @media (max-width: 630px) {
        display: block;
    }
}
.tradeMobItem {
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
}
.tradeMobItemTitle {
    margin-bottom: 15px;
    color: #131217 !important;
    position: relative;
    z-index: 2;
}
.tradeMobItemText {
    color: #131217 !important;
    position: relative;
    z-index: 2;
}
.tradeMobBtn {
    margin-top: 50px;
    position: relative;
    z-index: 2;
}

.imgMob {
    margin-top: 50px;
    display: none;
    position: relative;
    z-index: 2;
    text-align: right;
    @media (max-width: 630px) {
        display: block;
        img {
            max-width: 100%;
        }
    }
}
